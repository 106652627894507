.gradient-background {
  background: linear-gradient(300deg, #00bfff, #ff4c68, #ef8172);
  background-size: 180% 180%;
  animation: gradient-animation 18s ease infinite;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.icon-square {
  width: 30px;
  height: 30px;
  border-radius: 0.3rem;
}

.icon-square:hover {
  opacity: 0.5 !important;
}

.profile-img {
  height: 100px;
  border-radius: 50%;
}

.bg-primary {
  background-color: #262439 !important;
}

.bg-purple {
  background: var(--background-var-1, #6344C6) !important; 
}

.bg-light-purple {
  background: var(--background-var-1, #312F44) !important; 
}

.bg-yellow {
  background: var(--background-footer, #FFC448); 
}

.svg-color {
  fill: #262439;
  display: block;
}

.svg-button {
  fill:#262439;
}

.btn-primary {
  display: flex !important;
  border-radius: 40px !important;
  background: var(--button-main, #6344C6) !important;
  padding: 24px 36px !important;
  align-items: center !important;
  gap: 8px !important;
  font-size: 18px !important; 
  font-weight: bold !important;
  line-height: 18px !important;
  border: 0 !important;
  color:#262439 !important;
}

.btn-primary:hover {
  background: var(--button-main, #6344C6) !important;
  opacity: 0.5 !important;
}

.btn-primary:focus {
  background: var(--button-main, #6344C6) !important;
  opacity: 1 !important;
}

.btn-outline-secondary {
  border-radius: 40px !important;
  border: 1px solid var(--neutral-colors-color-600, #D4D2E3) !important; 
  background: #262439 !important; 
  padding: 24px 36px !important;
  align-items: center !important;
  gap: 8px !important;
  font-size: 18px !important;
  line-height: 18px !important;
  color: var(--neutral-colors-white, #FFF) !important; 
}

.btn-outline-secondary:hover {
  opacity: 0.5 !important;
}

.btn-white {
  display: flex !important;
  border-radius: 40px !important;
  background: var(--button-main, #ffffff) !important;
  padding: 24px 36px !important;
  gap: 8px !important;
  font-size: 18px !important;
  font-weight: bold !important;
  line-height: 18px !important;
  border: 0 !important;
  color:#6344C6 !important;
}

.btn-white:hover {
  opacity: 0.5 !important;
}

.text-secondary {
  color: var(--background, #262439) !important; 
}

.services {
  border-radius: 15px;
  border: 1px solid var(--neutral-colors-color-600, #D4D2E3);
  background: var(--neutral-colors-white, #FFF); 
}

.accordion-button {
  font-size: 24px !important;
  font-weight: 700 !important;
  line-height: 34px !important;
  border: none !important;
  border-radius: 15px !important;
}

.accordion-button:not(.collapsed) {
  background: var(--neutral-colors-white, #FFF); 
  background-color: var(--neutral-colors-white, #FFF) !important; 
  box-shadow: none !important;
  color: var(--background-v-2, #312F44);
}

.accordion-item:first-of-type {
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
}

.accordion-item:last-of-type {
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}

.accordion-body {
  font-size: 18px;
  line-height: 30px;
}

.testimonial-box {
  border-radius: 24px;
  border: 1px solid var(--neutral-colors-color-600, #D4D2E3); 
}

.testimonial-img {
  border-radius: 80px; 
}